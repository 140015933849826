/**
 *
 * Contains Payment API helper functions
 * @author Chad Watson
 *
 */
import { authInterceptor } from "apis/authInterceptor";
import { selectAuthUserCodeForUrl } from "apis/vk";
import axios from "axios";
import { payServer as payServerUrl } from "config/urls";
import { identity } from "ramda";
import store from "store";
import { selectDefaultAuthToken } from "store/common/selectors";

export const payServerApiInstance = axios.create({
  baseURL: payServerUrl,
  timeout: 3000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

payServerApiInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const authToken = selectDefaultAuthToken(state);
  const authUserCode = selectAuthUserCodeForUrl(state, config);

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  if (authUserCode && !config.headers.Authorization) {
    config.headers[`Authorization-Code`] = authUserCode;
  }

  return { ...config };
});

payServerApiInstance.interceptors.response.use(identity, authInterceptor);
