import axios, { AxiosError } from "axios";
import { getGQLUrl } from "common/utils/universal/securecom-urls";
import { identity } from "ramda";
import {
  dispatchLogOut,
  dispatchUpdateSessionTokens,
} from "resources/utils/json";
import store from "store";
import { selectRefreshToken } from "store/auth/selectors";
import { selectDefaultAuthToken } from "store/common/selectors";

export const authInterceptor = (error: AxiosError) => {
  const originalRequestConfig = error.config;

  if (error.response?.status === 401) {
    try {
      refreshLogin().then(
        () =>
          originalRequestConfig ? axios.request(originalRequestConfig) : null //After we refresh, we need to re-run the original request
      );
    } catch (error) {
      dispatchLogOut();
    }
  }
  return Promise.reject(error); //This has to be here or it breaks sagas
};

const gqlClient = axios.create({
  baseURL:
    process.env.REACT_APP_GRAPHQL_ENDPOINT ??
    getGQLUrl(process.env.REACT_APP_SECURECOM_ENV as any),
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

gqlClient.interceptors.request.use((config) => {
  const state = store.getState();
  const authToken = selectDefaultAuthToken(state);
  config.headers.Authorization = authToken;
  return config;
});
gqlClient.interceptors.response.use(identity, authInterceptor);

export const refreshLogin = async (passthroughRefreshToken?: string) => {
  const state = store.getState();
  // @ts-ignore compose in selectRefreshToken is not typing correctly with number of arguments
  const refreshToken = passthroughRefreshToken ?? selectRefreshToken(state);

  //Using mutation here instead of standard API call so auth context gets updated in GQL
  const response = await gqlClient.post(
    "/",
    {
      query:
        "mutation authInterceptorRefreshMutation(\n  $clientApplication: ClientApplication!\n  $refreshToken: String!\n) {\n  refreshSession(\n    clientApplication: $clientApplication\n    refreshToken: $refreshToken\n  ) {\n    ... on AuthenticateV4SuccessResponse {\n      rawUser \n      appUsers: users {\n        bearerToken\n        refreshToken\n        user {\n          id\n          email\n          ... on CustomerUser {\n            id\n            role\n            customer {\n              id\n              name\n              dealer {\n                id\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n    ...on AuthenticateErrorResponse {\n      error {\n        ...on Error {\n          type\n        }\n      }\n    }\n  }\n}",
      variables: {
        refreshToken: refreshToken,
        clientApplication: "VIRTUAL_KEYPAD",
      },
    },
    {
      headers: {
        "staging-branch": process.env.REACT_APP_CLIENT_STAGING_BRANCH,
      },
    }
  );

  //Using errors generically here because basically any bad response from the `refresh_session` endpoint means we have an invalid or expired refresh token
  if (response?.data?.errors || response?.data?.data?.refreshSession?.error) {
    dispatchLogOut();
    const errors = response?.data?.errors
      ? response.data.errors
      : response?.data?.data?.refreshSession?.error
      ? response.data.data.refreshSession.error
      : response;
    return errors;
  } else if (response?.data?.data?.refreshSession?.rawUser) {
    const data = JSON.parse(response.data.data.refreshSession.rawUser);
    dispatchUpdateSessionTokens(data.jwt, data.refresh_token);
    return response;
  }
};
