/**
 *
 * Contains Jobs API helper functions
 * @author Chad Watson
 *
 */

import { authInterceptor } from "apis/authInterceptor";
import { selectAuthUserCodeForUrl } from "apis/vk";
import axios from "axios";
import { jobs as jobsUrl } from "config/urls";
import { identity } from "ramda";
import store from "store";
import {
  selectAuthTokenForSystem,
  selectDefaultAuthToken,
} from "store/common/selectors";

export const jobsApiInstance = axios.create({
  baseURL: jobsUrl,
  timeout: 3000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const selectAuthTokenForUrl = (state, config) => {
  const [, controlSystemId] =
    config.url.match(/\/api\/v1\/pod\/PanelOD\/panel\/([0-9]+)/) ?? [];
  if (controlSystemId) {
    return selectAuthTokenForSystem(state, {
      systemId: Number(controlSystemId),
    });
  }

  return selectDefaultAuthToken(state);
};

jobsApiInstance.interceptors.request.use((config) => {
  // Check if auth token is being refreshed before making the request.

  const state = store.getState();
  const authToken = selectAuthTokenForUrl(state, config);
  const authUserCode = selectAuthUserCodeForUrl(state, config);

  // Only assign Authorization if it doesn't exist
  if (authToken && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  if (authUserCode && !config.headers.Authorization) {
    config.headers[`Authorization-Code`] = authUserCode;
  }

  return { ...config };
});

jobsApiInstance.interceptors.response.use(identity, authInterceptor);
