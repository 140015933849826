/**
 *
 * Handles Job API calls
 * @author Matt Shaffer
 *
 */

import { authInterceptor } from "apis/authInterceptor";
import axios from "axios";
import { MAX_POLL_COUNT, POLL_DELAY_IN_MILLISECONDS } from "config/app";
import { jobStat as jobStatUrl } from "config/urls";
import { identity } from "ramda";
import store from "store";
import { selectDefaultAuthToken } from "store/common/selectors";
import { sleep } from "utils";
import { selectAuthUserCodeForUrl } from ".";

export const jobStatApi = axios.create({
  baseURL: jobStatUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const odataRequestInterceptor = (config) => {
  const state = store.getState();
  const authToken = selectDefaultAuthToken(state, config);
  const authUserCode = selectAuthUserCodeForUrl(state, config);

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  if (authUserCode && !config.headers.Authorization) {
    config.headers[`Authorization-Code`] = authUserCode;
  }

  return { ...config };
};

jobStatApi.interceptors.request.use(odataRequestInterceptor);
jobStatApi.interceptors.response.use(identity, authInterceptor);

const CONNECTION_TIMEOUT_ERROR_CODE = 7;

const v1JobPollError = {
  data: {
    status: "error",
    error_code: CONNECTION_TIMEOUT_ERROR_CODE,
    error_message: "Polling timed out.",
  },
};

const v2JobPollError = {
  data: {
    job: {
      status: "error",
      error_code: CONNECTION_TIMEOUT_ERROR_CODE,
      error_message: "Polling timed out.",
    },
  },
};

const v1JobStatusUrl = (jobNumber) => `1/jobs/${jobNumber}/status`;

const v2JobStatusUrl = (uuid) => `v2/jobs/${uuid}`;

export const getV1JobStatus = (jobNumber) =>
  jobStatApi.get(v1JobStatusUrl(jobNumber));

export const getV2JobStatus = (uuid) => jobStatApi.get(v2JobStatusUrl(uuid));

const waitForV1JobToFinish = async (apiResponse, timesPolled = 0) => {
  const { status, job_number: jobNumber } = apiResponse.data;

  if (timesPolled > MAX_POLL_COUNT) {
    throw v1JobPollError;
  }

  if (status === "success") {
    return apiResponse;
  } else if (status === "pending") {
    if (!jobNumber) {
      throw v1JobPollError;
    }

    await sleep(POLL_DELAY_IN_MILLISECONDS);
    const nextApiResponse = await getV1JobStatus(jobNumber);
    return waitForV1JobToFinish(nextApiResponse, timesPolled + 1);
  }

  throw apiResponse;
};

const waitForV2JobToFinish = async (apiResponse, timesPolled = 0) => {
  const { status, uuid } = apiResponse.data.job;

  if (timesPolled > MAX_POLL_COUNT) {
    throw v2JobPollError;
  }

  if (status === "success") {
    return apiResponse;
  } else if (status === "pending") {
    if (!uuid) {
      throw v2JobPollError;
    }

    await sleep(POLL_DELAY_IN_MILLISECONDS);
    const nextApiResponse = await getV2JobStatus(uuid);
    return waitForV2JobToFinish(nextApiResponse, timesPolled + 1);
  }

  throw apiResponse;
};

/**
 * Waits for a pending job to complete
 * @param  {object} request
 * @return {promise}
 */
export const waitForJobToFinish = async (request) => {
  const response = await request;
  return "job" in response.data
    ? waitForV2JobToFinish(response)
    : waitForV1JobToFinish(response);
};
