import axios from "axios";
import { vk as vkUrl } from "config/urls";
import { identity } from "ramda";
import store from "store";
import { selectAuthTokenForCustomer } from "store/auth/selectors";
import {
  selectActiveSystem,
  selectAuthTokenForSystem,
  selectDefaultAuthToken,
} from "store/common/selectors";
import { selectLegacyPanelId, selectUserCode } from "store/systems/selectors";
import { authInterceptor } from "../authInterceptor";
import { waitForJobToFinish } from "./jobs";
import * as paging from "./paging";

export const vkApiInstance = axios.create({
  baseURL: vkUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const selectAuthTokenForUrl = (state, config) => {
  const [, customerId] = config.url.match(/\/v2\/customers\/([0-9]+)/) ?? [];
  const [, , controlSystemId] =
    config.url.match(/\/v2\/(control_systems|panels)\/([0-9]+)/) ?? [];

  if (customerId) {
    return selectAuthTokenForCustomer(state, {
      customerId: Number(customerId),
    });
  } else if (controlSystemId) {
    return selectAuthTokenForSystem(state, {
      systemId: Number(controlSystemId),
    });
  } else {
    return selectDefaultAuthToken(state);
  }
};

export const selectAuthUserCodeForUrl = (state, config) => {
  const activeSystem = selectActiveSystem(state);
  if (activeSystem) {
    const [, panelId] = config.url.match(/\/v2\/panels\/([0-9]+)/) ?? [];
    if (panelId && Number(panelId) === activeSystem.id) {
      return selectUserCode(state, { systemId: activeSystem.id });
    }

    const activeSystemLegacyPanelId = selectLegacyPanelId(state, {
      systemId: activeSystem.id,
    });
    const [, legacyPanelId] =
      config.url.match(/\/1\/panels\/([0-9]+-[0-9]+)/) ?? [];
    if (legacyPanelId && legacyPanelId === activeSystemLegacyPanelId) {
      return selectUserCode(state, { systemId: activeSystem.id });
    }
  }

  return undefined;
};

export const vkApiRequestInterceptor = async (config) => {
  const state = store.getState();
  const authToken = selectAuthTokenForUrl(state, config);
  const authUserCode = selectAuthUserCodeForUrl(state, config);
  const params = config.params ?? {};

  // Only assign Authorization if it doesn't exist
  if (authToken && !config.headers.Authorization) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  if (authUserCode) {
    config.headers["Authorization-Code"] = authUserCode;
  }

  return { ...config, params };
};

vkApiInstance.interceptors.request.use(vkApiRequestInterceptor);
vkApiInstance.interceptors.response.use(identity, authInterceptor);

/**
 * Refreshes a V2 API resource from the panel and waits for the job to finish
 * @param  {string} baseUrl this is without the "/refresh" at the end of the url
 * @return {promise}
 */
const refreshV2Resource = (baseUrl) =>
  waitForJobToFinish(vkApiInstance.post(`${baseUrl}/refresh`));

const getV2ResourceWithPaging = ({ baseUrl, getAllResults }) =>
  getAllResults
    ? paging.getAllResults({
        apiCall: (params) => vkApiInstance.get(baseUrl, params),
      })
    : vkApiInstance.get(baseUrl);

/**
 * Requests a V2 API resource. If refresh is true, it refreshes the panel resource
 * and then recurses with refresh=false to immediately request the newly cached resource
 * @param  {integer} baseUrl
 * @param  {boolean} refresh
 * @return {promise}
 */
export const getV2Resource = ({ baseUrl, refresh, getAllResults }) =>
  refresh
    ? refreshV2Resource(baseUrl).then(() =>
        getV2ResourceWithPaging({ baseUrl, getAllResults })
      )
    : getV2ResourceWithPaging({ baseUrl, getAllResults });
