import { addKeyIfNotNullOrUndefined } from "common/utils/universal/object";
import { vkApiInstance } from ".";

const getRandomChar = (min, max) =>
  String.fromCharCode(Math.floor(Math.random() * (max - min)) + min);

export const getRandomLetter = () =>
  Math.round(Math.random()) ? getRandomChar(65, 90) : getRandomChar(97, 122);

export const getPart = ({ systemId, number, authUserCode }) => {
  const url = `/v2/parts_catalog/${getRandomLetter()}${getRandomLetter()}-${systemId}-${number}/details`;
  return vkApiInstance
    .get(url, {
      headers: {
        ...addKeyIfNotNullOrUndefined("Authorization-Code", authUserCode),
      },
    })
    .then((response) => response.data);
};
