/**
 *
 * Zone Information
 * @author Matt Shaffer
 *
 */
import { vkApiInstance } from "apis/vk";
import { waitForJobToFinish } from "./jobs";
import { getAllResults } from "./paging";

const getBaseUrl = (systemId) => `/v2/panels/${systemId}/zone_informations`;

/**
 * Fetches all zone informations
 * @param  {integer} panelId
 * @return {promise}
 */
export const getAll = ({ systemId }) =>
  getAllResults({
    apiCall: (params) => vkApiInstance.get(getBaseUrl(systemId), params),
  });

/**
 * Refreshes all zone informations
 * @param  {integer} panelId
 * @return {promise}
 */
export const refresh = ({ systemId }) =>
  waitForJobToFinish(vkApiInstance.post(`${getBaseUrl(systemId)}/refresh`));
